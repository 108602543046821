import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../components/auth/auth.service';
import { COMMON_MESSAGES } from '../shared/data/common-messages.data';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private notificationService: NotificationService, private authService: AuthService) {}

  handle400Error(error: HttpErrorResponse) {
    this.notificationService.error(this.getErrorMessage(error));
  }

  handle401Error(error: HttpErrorResponse) {
    console.log('error interceptor', error);
    if (error.url.split('/').includes('refresh-token')) {
      this.notificationService.error(this.getErrorMessage(error));
      this.authService.logout();
    }
  }

  handle403Error(error: HttpErrorResponse) {
    this.notificationService.error(this.getErrorMessage(error));
    this.authService.logout();
  }

  handle404Error(error: HttpErrorResponse) {
    this.notificationService.error(this.getErrorMessage(error));
  }

  handle405Error(error: HttpErrorResponse) {
    this.notificationService.error(this.getErrorMessage(error));
  }

  handle429Error(error: HttpErrorResponse) {
    this.notificationService.error(this.getErrorMessage(error));
  }

  handle500Error(error: HttpErrorResponse) {
    this.notificationService.error(this.getErrorMessage(COMMON_MESSAGES.serverIsNotResponsible));
  }

  getErrorMessage(error: HttpErrorResponse): string {
    return error.error && error.error.message
      ? error.error.message
      : error.statusText
      ? error.statusText
      : COMMON_MESSAGES.unknownError;
  }
}
