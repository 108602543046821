import { Injectable, Injector } from '@angular/core';
import { StorageService } from '../shared/storage/local-storage.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<any> {
  constructor(injector: Injector,storageService: StorageService) {
    super('users', injector,storageService);
  }

 

}
