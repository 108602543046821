import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { StorageService } from 'src/app/shared/storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    private authTokenSubject: BehaviorSubject<any>;
    public authToken: Observable<any>;
    constructor(
        private storageService: StorageService,
        private router: Router,
        private userService: UserService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(
            this.storageService.get('user_info')
        );
        this.currentUser = this.currentUserSubject.asObservable();

        this.authTokenSubject = new BehaviorSubject<any>(
            this.storageService.get('access_token')
        );
        this.authToken = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get authTokenValue(): any {
        return this.authTokenSubject.value;
    }

    loginSetStorage(data: any) {
        this.storageService.set('user_info', data);
        this.storageService.set('access_token', data.tokens.access_token);
        this.storageService.set('refresh_token', data.tokens.refresh_token);
        this.currentUserSubject.next(data);
        this.router.navigateByUrl('/main/pages');
    }

    logout() {
        console.log('Logout');
        const refresh_token = this.storageService.get('refresh_token');
        this.userService.create({ refresh_token: refresh_token }, null, 'logout/').subscribe(() => {

        });
        this.storageService.removeItem('user_info');
        this.storageService.removeItem('access_token');
        this.storageService.removeItem('refresh_token');
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('/');
    }




}
