// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://crr.apasplustest.com',
  firebaseConfig:{
    apiKey: "AIzaSyDAluPKMJE3RDGFQhZqH9xa2BH-OPCpMbk",
    authDomain: "kuryemgelsin-861a0.firebaseapp.com",
    projectId: "kuryemgelsin-861a0",
    storageBucket: "kuryemgelsin-861a0.appspot.com",
    messagingSenderId: "739736047744",
    appId: "1:739736047744:web:ca515c0c2f5da837ab5665",
    measurementId: "G-4WP4Q83285",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
