import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            {
                items: [
                    {
                        label: 'Genel Bakış',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/main/pages/'],
                    },
                    {
                        label: 'Kullanıcılar',
                        icon: 'pi pi-fw pi-check-square',
                        routerLink: ['/main/pages/all-users'],
                    },
                    {
                        label: 'Siparişler',
                        icon: 'pi pi-fw pi-id-card',
                        routerLink: ['/main/pages/'],
                        items: [
                            {
                                label: 'Onay Bekleyen Siparişler',
                                routerLink: ['/main/pages/siparisler/onay_bekleniyor'],

                            },
                            {
                                label: 'Devam Eden Siparişler',
                                routerLink: ['/main/pages/siparisler/devam_ediyor'],

                            },
                            {
                                label: 'Tamamlanan Siparişler',
                                routerLink: ['/main/pages/siparisler/tamamlandi'],

                            },
                            {
                                label: 'Iptal Edilen Siparişler',
                                routerLink: ['/main/pages/siparisler/iptal_edildi'],

                            },
                        ]
                    },

                    {
                        label: 'Finansal İşlemler',
                        icon: 'pi pi-fw pi-dollar',
                        routerLink: ['/main/pages/finansal-islemler'],
                    },

                    {
                        label: 'Ödeme Durumları',
                        icon: 'pi pi-credit-card',
                        routerLink: ['/main/pages/odeme-durum'],
                    },
                    {
                        label: 'Bildirim Gönder',
                        icon: 'pi pi-fw pi-send',
                        routerLink: ['/main/pages/bildirim-gonder'],
                    },
                    {
                        label: 'Ayarlar',
                        icon: 'pi pi-fw pi-exclamation-circle',
                        routerLink: ['/main/pages/ayarlar'],
                    },
                ],
            },
        ];
    }
}
