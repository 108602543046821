import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { ProgressBarService } from './service/progress-bar.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  loading$: Observable<boolean>;

    constructor(private primengConfig: PrimeNGConfig,private progressBarService: ProgressBarService) {
    this.loading$ = this.progressBarService.loading$;

    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        
    }
   
   
}
