import { Injectable, Injector } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    Observable,
    switchMap,
    throwError,
} from 'rxjs';
import { AuthService } from '../components/auth/auth.service';
import { UserService } from '../service/user.service';
import { StorageService } from '../shared/storage/local-storage.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class InterCeptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private inject: Injector,
        private authService: AuthService,
        private userService: UserService,
        private storageService: StorageService,
        private messageService: MessageService
    ) {}
    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        let auth = this.inject.get(AuthService);
        if (auth.authToken) {
            let cloned = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                },
            });
            if (
                !request.url.split('/').includes('login') &&
                !request.url.split('/').includes('logout') &&
                !request.url.split('/').includes('refresh')
            ) {
                cloned = request.clone({
                    headers: request.headers.set(
                        'Authorization',
                        `Bearer ${this.storageService.get('access_token')}`
                    ),
                });
            }
            return next.handle(cloned).pipe(
                catchError(async (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        return this.handle401Error(request, next);
                    } else if (error.status === 500 || error.status === 400) {
                        console.log('Errr', error);
                        return this.messageService.add({
                            severity: 'error',
                            summary: 'Hata !',
                            detail: error.error.message,
                        });
                    } else {
                        return throwError(async () => {
                            error;
                        });
                    }
                })
            ) as any;
        } else {
            return next.handle(request);
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);
            const refresh_token = this.storageService.get('refresh_token');
            return this.userService
                .create({ refresh_token: refresh_token },null,'refresh')
                .pipe(
                    switchMap((token: any) => {
                        this.isRefreshingToken = false;
                        this.storageService.set('access_token', token.token);
                        return next.handle(this.addToken(request));
                    })
                )
                .subscribe({
                    next: (res) => {
                        console.log('Resp next', res);
                    },
                    error: (err) => {
                        console.log('Resp next', err);
                    },
                });
        } else {
            this.isRefreshingToken = false;
            this.authService.logout();
            return false;
        }
    }

    addToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({
            headers: req.headers.set(
                'Authorization',
                `Bearer ${this.storageService.get('access_token')}`
            ),
        });
    }
}
