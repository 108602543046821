
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { setQueryParams } from '../shared/global-functions';
import { StorageService } from '../shared/storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class BaseService<T> implements OnDestroy {
    http: HttpClient;
    apiAddress!: string;
    subs: Array<Subscription> = [];
    set setSubs(value: Subscription) {
        this.subs.push(value);
    }
    constructor(@Inject(String) private controlName: string, private injector: Injector, private storageService: StorageService) {
        this.apiAddress = `${environment.apiUrl}/tr/api/${this.controlName}`;
        this.http = this.injector.get(HttpClient);

    }

    //TODO for client side caching
    private $$data: { [key: string]: Subject<Array<any>> } = {};

    public getAll<T>(options = {}): Observable<T> {
        return this.http.get<T>(this.apiAddress, options);
    }

    public queryParamsGet<T>(value: any, id?: any, extraPath?: any): Observable<T> {
        let path = this.apiAddress;
        let params = new HttpParams();
        if (value) {
            params = setQueryParams(value, params);
        }
        if (id) {
            path += `/${id}`;
        }
        if (extraPath) {
            path += `/${extraPath}`;
        }
        return this.http.get<T>(path, { params: params });
    }

    public get<T>(id?: any, extraPath?:any): Observable<T> {
        let path = this.apiAddress;
        if (id) {
            path += `/${id}`;
        }
        if (extraPath) {
            path += `/${extraPath}`;
        }
        return this.http.get<T>(path);
    }

    public create<T>(model: T, id?:any, extraPath?:any, extraId?:any): Observable<any> {
        let path = this.apiAddress;
        if (id) {
            path += `/${id}`;
        }
        if (extraPath) {
            path += `/${extraPath}`;
        }
        if (extraId) {
            path += `/${extraId}`;
        }
        return this.http.post<any>(path, model);
    }

    public update<T>(model: T, id?:any, extraPath?:any, itemId?:any): Observable<any> {
        let path = this.apiAddress;
        if (id) {
            path += `/${id}`;
        }
        if (extraPath) {
            path += `/${extraPath}`;
        }
        if (itemId) {
            path += `/${itemId}`;
        }
        return this.http.patch<any>(path, model);
    }
    public put<T>(model: T, id?:any, extraPath?:any, itemId?:any): Observable<any> {
        let path = this.apiAddress;
        if (id) {
            path += `/${id}`;
        }
        if (extraPath) {
            path += `/${extraPath}`;
        }
        if (itemId) {
            path += `/${itemId}`;
        }
        return this.http.put<any>(path, model);
    }

    public delete<T>(id: any, extraPath?:any, itemId?:any): Observable<any> {
        let path = this.apiAddress;

        if (id) {
            path += `/${id}`;
        }
        if (extraPath) {
            path += `/${extraPath}`;
        }
        if (itemId) {
            path += `/${itemId}`;
        }
        return this.http.delete<any>(path);
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => {
            s.unsubscribe();
        });
    }
}
