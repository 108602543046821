import { FormControl, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

export const setQueryParams = (form: FormGroup, params: HttpParams) => {
  /** HTTPParams ve reactive form  tipinde iki veri alınır.
   * Form gerekli düzenlemelerden geçirildikten sonra (temizlik vb)
   * HTTPParams'a çevirilir.
   *
   */

  form = deepCopyReactiveForm(form);
  Object.keys(form.value).forEach((key) => {
    if (typeof form.value[key] === 'object') {
      // Eğer fomdan gelen field bir obje ise tekrar loopa sokuyoruz
      Object.keys(form.value[key]).forEach((keyArr) => {
        params.set(key, form.value[key][keyArr][key]);
        params = params.append(key, form.value[key][keyArr][key]);
      });
    } else {
      params.set(key, form.value[key]);
      params = params.append(key, form.value[key]);
    }
  });

  return params;
};

export const deepCopyReactiveForm = (form: FormGroup) => {
  /** Eğer Formun üzerinde değişiklik yapılacaksa ve bu değişikliğin görüntülenen sayfayı etkilemesi istenmiyorsa
   * (Date tipindeki verinin timestamp'e çevirilmesi gibi durumlarda)
   * form nesnesinin bir kopyası oluşturulur ve değişklik işlemleri bu yeni kopyanın üzerinde yapılır.
   */
  let newForm = new FormGroup({});
  Object.keys(form.controls).forEach((key) => {
    const control: FormControl = form.controls[key] as FormControl;
    const controlValue: any = control.value;
    newForm.addControl(key, new FormControl(controlValue));
  });

  newForm = clearReactiveForm(newForm);

  return newForm;
};

export const clearReactiveForm = (form: FormGroup) => {
  /**
   * Formda null atanan değerler silinir ki url'de null bir value taşınmasın diye.
   * Formda date tipinde bir veri mevcutsa bu veri stringe 'e dönüştürülür.
   * İhtiyaçlar doğrultusunda bu fonksiyon geliştirilebilir.
   */
  Object.keys(form.controls).forEach((key) => {
    const control: FormControl = form.controls[key] as FormControl;
    const controlValue: any = control.value;
    if (controlValue == undefined || controlValue == null) {
      form.removeControl(key);
    }

    if (controlValue instanceof Date) {
      if (key == 'baslangic_tarihi') {
        controlValue.setHours(0);
      }
      if (key == 'bitis_tarihi') {
        controlValue.setHours(24);
        controlValue.setMilliseconds(-1);
      }
      control.setValue(controlValue.toISOString());
    }
  });
  return form;
};


export function scrollToFirst() {
  window.scrollTo(0, 0);
}

export function resizeImage(src, genisKenar?) {
  let newX;
  let newY;
  let ratio;
  let genislik = 800;
  if (genisKenar) {
    genislik = genisKenar;
  }
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      // resimin çözünürlüğünü alıyoruz.
      newX = img.width;
      newY = img.height;
      // resimin uzun kenarının 800px olması için gerekli katsayı ratio hesaplanıyor.
      if (newX > newY) {
        ratio = genislik / newX;
      } else {
        ratio = genislik / newY;
      }
      // genişlik ve yükseklik değerlerinin değiştiryoruz.
      newX = img.width * ratio;
      newY = img.height * ratio;

      // resme hesapladığımız genişlik ve yükseklik değerlerini uyguluyoruz.
      img.width = newX;
      img.height = newY;
      // canvasa hesapladığımız genişlik ve yükseklik değerlerini uyguluyoruz.
      elem.width = newX;
      elem.height = newY;

      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    };
    img.onerror = (error) => rej(error);
  });
}