import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AuthGuard } from './components/auth/auth-guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterCeptor } from './core/interceptor';
import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { ProgressBarInterceptor } from './core/progress-bar.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from "../environments/environment";
import { AgmCoreModule } from '@agm/core';
@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        AppLayoutModule,
        ToastModule,
        ProgressSpinnerModule,
    
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        MessageService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterCeptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProgressBarInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
